<template>
  <div class="auth-page">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="col-12 col-md-6 d-none d-md-block">
          <div class="auth-page-left">
            <div class="logo">
              <img src="../../../assets/images/logo.png" alt="" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="auth-page-right">
            <div class="logo">
              <h4>Jewellery</h4>
            </div>

            <div class="auth-page-right-content text-center">
              <h6>Password Updated</h6>
              <div class="success-img">
                <img src="../../../assets/images/success.png" alt="" />
              </div>
              <p>Your Password has been updated!</p>
              <button
                type="button"
                class="auth-btn"
                @click="passwordUpdate(0)"
                :disabled="isLoadingArray[0]"
              >
                <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                <span v-else>Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PasswordUpdate",

  components: {},

  data: function() {
    return {
      isLoadingArray: [],
    };
  },

  computed: {
    ...mapGetters(["errors"]),
  },

  mounted() {
    this.$store.commit("setErrors", {});
  },

  methods: {
    ...mapActions("auth", ["sendLoginRequest"]),

    passwordUpdate: function(index) {
      this.$set(this.isLoadingArray, index, true);
      setTimeout(() => {
        this.$set(this.isLoadingArray, index, false);
        this.$router.push({ name: "Login" });
      }, 500);
    },
  },
};
</script>
